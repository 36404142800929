import React from "react";
import Task from "../types/Task";
import Button from "@paprika/button";
import "./TaskView.scss";

type Props = {
  task: Task;
  positive: (task) => void;
  negative: (task) => void;
  skip: (task) => void;
};

export default function TaskView({ task, positive, negative, skip }: Props) {
  const onPositive = () => {
    positive(task);
  };

  const onNegative = () => {
    negative(task);
  };

  const onSkip = () => {
    skip(task);
  };

  return (
    <div className="task">
      <div className="question">{task.question}</div>
      <div className="items">
        <div className="container">
          {task.items.map((item, index) => {
            return (
              <div key={index} className="item">
                {item}
              </div>
            );
          })}
        </div>
      </div>

      <div className="answers">
        <Button kind="primary" onClick={onPositive}>
          Yes
        </Button>
        &nbsp;&nbsp;
        <Button kind="secondary" onClick={onNegative}>
          No
        </Button>
        &nbsp;&nbsp;
        <Button kind="destructive" onClick={onSkip}>
          Skip
        </Button>
      </div>
    </div>
  );
}
