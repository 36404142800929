import React, { useState, useEffect } from "react";
import { Task } from "../types/Task";
import TaskView from "./TaskView";
import { useApi } from "../providers/BatonApiProvider";
import "./Baton.scss";
import { useCookies } from 'react-cookie';
import config from "config";

type Props = {};

export default function Baton(props: Props) {
  const getServer = () => {
    return config.urls.batonApiUrl;
  };

  const userId = "current_user_id";
  const api = useApi();
  const [task, setTask] = useState<Task | undefined>(undefined);
  const [cookies] = useCookies(['userToken']); //TODO: passing token through cookies doesn't work on first page load after login

  const getTask = async () => {
    setTask(undefined);
    const task = await api.load(getServer(), cookies.userToken);
    setTask(task);
  };

  const saveTask = async (task: Task, answer: boolean) => {
    if (!task.results) task.results = [];
    task.results.push({
      userId: userId,
      answer: answer,
    });
    await api.save(getServer(), task, cookies.userToken);
    getTask();
  };

  const onPositive = async (task: Task) => {
    saveTask(task, true);
  };

  const onNegative = async (task: Task) => {
    saveTask(task, false);
  };

  const onSkip = async (task) => {
    getTask();
  };

  useEffect(() => {
    getTask();
  }, []);

  return (
    <div className="baton">
      {task !== undefined && <TaskView task={task as Task} positive={onPositive} negative={onNegative} skip={onSkip} />}
    </div>
  );
}
