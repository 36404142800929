const config = {
    urls: {
      cognitoHost: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
      loginUrl: `${process.env.REACT_APP_COGNITO_DOMAIN}/login`,
      batonApiUrl: `${process.env.REACT_APP_BATON_API_URL}` || 'https://sx5ijqpce6.execute-api.us-west-2.amazonaws.com/v1',
    },
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    cognitoClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
    region: `${process.env.REACT_APP_REGION}`,
  };
  
  export default config;