import React from "react";
import BatonApiService from "services/BatonApiService";

export const ApiContext = React.createContext<BatonApiService | undefined>(undefined);

export function useApi() {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within an BatonApiProvider");
  }
  return context;
}

export default function BatonApiProvider({ children }: { children: React.ReactNode }) {
  return <ApiContext.Provider value={new BatonApiService()}>{children}</ApiContext.Provider>;
}
