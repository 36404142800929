import React from "react";
import GlobalNavigation, { Logo, CurrentApp, Helper, User } from "sriracha/dist/GlobalNavigation";
import Baton from "./components/Baton";
import "sriracha/dist/GlobalNavigation.scss";
import "./App.scss";
import { useCookies } from 'react-cookie';
import config from "config";
import crypto from "crypto";

import BatonApiProvider from "providers/BatonApiProvider";

async function fetchUserInfo (token: string) {
  const url = `${config.urls.cognitoHost}/oauth2/userInfo`
  return await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
} 

//Convert Payload from Base64-URL to JSON
const decodePayload = payload => {
  const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = atob(cleanedPayload)
  const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
    const uriEncodedChar = ('00' + char.charCodeAt(0).toString(16)).slice(-2)
    return `${acc}%${uriEncodedChar}`
  }, '')
  const jsonPayload = decodeURIComponent(uriEncodedPayload);

  return JSON.parse(jsonPayload)
}

//Parse JWT Payload
const parseJWTPayload = token => {
  const [header, payload, signature] = token.split('.');
  const jsonPayload = decodePayload(payload)

  return jsonPayload
};

async function verifyToken (token: string): Promise<boolean> {
  //verify token has not expired
  const tokenPayload = parseJWTPayload(token);
  if (Date.now() >= tokenPayload.exp * 1000) {
    console.log("Token expired");
    return false;
  }

  //verify app_client_id
  if (tokenPayload.aud.localeCompare(config.cognitoClientId)){
    console.log("Token was not issued for this audience");
    return false;
  }

  return true;
}


function base64encode(str: string | Buffer): string {
  if (typeof str === "string") str = Buffer.from(str);
  return str.toString("base64");
}

function base64urlencode(str: string | Buffer): string {
  return base64encode(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function login () {
  console.log('LOGIN')
  // Create random "state"
  const state = crypto.randomBytes(16).toString("hex");
  sessionStorage.setItem("pkce_state", state);
  // Create PKCE code verifier
  const code_verifier = crypto.randomBytes(43).toString("hex");
  sessionStorage.setItem("code_verifier", code_verifier);

  const code_challenge = base64urlencode(
    crypto.createHash("sha256")
      .update(code_verifier)
      .digest("hex")
  );

  sessionStorage.setItem("code_challenge", code_challenge);

  global.location.replace(`${config.urls.loginUrl}?client_id=${config.cognitoClientId}&response_type=code&state=${state}&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${global.location.protocol}//${global.location.host}`);
  // TODO: if authorize with code_challenge, request to /tokens fails (https://aws.amazon.com/blogs/security/how-to-add-authentication-single-page-web-application-with-amazon-cognito-oauth2-implementation/, https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html)
  // global.location.replace(`${config.urls.loginUrl}?client_id=${config.cognitoClientId}&response_type=code&state=${state}&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${global.location.protocol}//${global.location.host}&code_challenge_method=S256&code_challenge=${code_challenge}`); 
  return null;
}

async function fetchTokens(code: string) {
  const code_verifier = sessionStorage.getItem('code_verifier');
  const url = `${config.urls.cognitoHost}/oauth2/token?grant_type=authorization_code&client_id=${config.cognitoClientId}&code=${code}&code_verifier=${code_verifier}&redirect_uri=${global.location.protocol}//${global.location.host}`;
  const response = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  return response.json();
}

function auth(setCookie: CallableFunction) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // Verify state matches
  const state = params.state;
  if(state && sessionStorage.getItem("pkce_state") !== state) {
      alert("Invalid state");
  } else {
    Promise.resolve(fetchTokens(params.code).then((tokens) => {
      Promise.resolve(verifyToken(tokens.id_token).then((verified) => {
        if (verified) {
          setCookie('userToken', tokens.id_token, { path: '/', maxAge: 86400 });
        } else {
          alert('Token is not valid')
        }
      }));
      Promise.resolve(fetchUserInfo(tokens.access_token).then((response) => {return response.json();}).then((data) => {
        alert(`Welcome ${data.username}`);
      }));
    }))
  }
  return !!params.code;
}


export default function App() {
  const [cookies, setCookie] = useCookies(['userToken']);
  if(!cookies.userToken && !auth(setCookie)) {
    return login();
  }
  return (
    <div className="layout">
      <BatonApiProvider>
        <div className="sriracha">
          <GlobalNavigation>
            <Logo launchpadUrl={"https://www.wegalvanize.com"} />
            <CurrentApp appModuleName="Maestra's Baton" appUrl="/" />
            <Helper helpDocUrl={"https://www.wegalvanize.com"} supportUrl={"https://www.wegalvanize.com"} />
            <User
              username={"Username"}
              userProfileUrl={"https://www.wegalvanize.com"}
              logoutUrl={"https://www.wegalvanize.com"}
            />
          </GlobalNavigation>
        </div>
        <Baton />
      </BatonApiProvider>
    </div>
  );
}
