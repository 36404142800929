import Task from "../types/Task";
export default class BatonApiService {
  async fetchData(domain: string, path: string, options: any = {}): Promise<Response> {
    const server = domain.replace(/\/$/g, "");
    return fetch(`${server}${path}`, options);
  }

  async load(domain: string, token: string): Promise<Task | undefined> {
    const response = await this.fetchData(domain, `/tasks/`, {
      method: "GET",
      headers: {"Authorization": token}});
    if (response.ok) {
      const responseJson = await response.json();
      if (responseJson && responseJson.data && responseJson.data.length > 0) {
        return responseJson.data[0] as Task;
      }
      return undefined;
    }
    return Promise.reject(response.status);
  }

  async save(domain: string, task: Task, token: string): Promise<void> {
    await this.fetchData(domain, `/tasks/`, {
      method: "POST",
      body: JSON.stringify({ data: [task] }),
      headers: {"Authorization": token}
    });
  }
}
